<template>
  <CasesTable :auditCases="true"></CasesTable>
</template>

<script>
import CasesTable from "./Cases/CasesTable.vue";
export default {
  name: "Audit cases",
  components: {
    CasesTable,
  },
  mounted() {
    this.$action("audit-cases-page-view");
  },
};
</script>
